<template>
  <div class="col-auto">
    <Button color="green_light" width="100" :btnText="btnText" icon="arrow" @buttonClicked="save" :disabled="saveDisabled || isLoading" :isLoading="isLoading">
      <IconArrowForward class="inline-icon" />
    </Button>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'SaveButton',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  props: ['saveDisabled', 'isLoading'],
  emits: ['save'],
  computed: {
    btnText () {
      if (this.isLoading) {
        return 'Saving...'
      } else {
        return 'Setup Now'
      }
    }
  },
  methods: {
    save () {
      console.log('SAVE')
      this.$emit('save')
    }
  }
}
</script>
